.user {
  &-avatar {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #e6edff;
    color: $primary-dark;
    text-align: center;
    display: block;
    padding: 10px;
    line-height: 38px;
    font-weight: 500;
    font-size: $font-size-lg;
    text-transform: uppercase;
    box-sizing: border-box;
  }

  &-name {
    @extend .text-medium;
    @extend .text-primary-dark;
    @extend .mb-0;
  }

  &-position {
    @extend .text-primary-light;
    @extend .mt-1;
    @extend .mb-0;
  }
}
