.container {
  width: $container-md;
  margin-right: auto;
  margin-left: auto;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.nowrap {
  flex-wrap: nowrap;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-offset-0,
.col-md-offset-1,
.col-md-offset-2,
.col-md-offset-3,
.col-md-offset-4,
.col-md-offset-5,
.col-md-offset-6,
.col-md-offset-7,
.col-md-offset-8,
.col-md-offset-9,
.col-md-offset-10,
.col-md-offset-11,
.col-md-offset-12,
.col-fifth {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

.col-fifth {
  width: 20%;
}

.col,
.col-md {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-1,
.col-md-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-2,
.col-md-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-3,
.col-md-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-4,
.col-md-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-5,
.col-md-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-6,
.col-md-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-7,
.col-md-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-8,
.col-md-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-9,
.col-md-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-10,
.col-md-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-11,
.col-md-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-12,
.col-md-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-offset-0,
.col-md-offset-0 {
  margin-left: 0;
}

.col-offset-1,
.col-md-offset-1 {
  margin-left: 8.33333333%;
}

.col-offset-2,
.col-md-offset-2 {
  margin-left: 16.66666667%;
}

.col-offset-3,
.col-md-offset-3 {
  margin-left: 25%;
}

.col-offset-4,
.col-md-offset-4 {
  margin-left: 33.33333333%;
}

.col-offset-5,
.col-md-offset-5 {
  margin-left: 41.66666667%;
}

.col-offset-6,
.col-md-offset-6 {
  margin-left: 50%;
}

.col-offset-7,
.col-md-offset-7 {
  margin-left: 58.33333333%;
}

.col-offset-8,
.col-md-offset-8 {
  margin-left: 66.66666667%;
}

.col-offset-9,
.col-md-offset-9 {
  margin-left: 75%;
}

.col-offset-10,
.col-md-offset-10 {
  margin-left: 83.33333333%;
}

.col-offset-11,
.col-md-offset-11 {
  margin-left: 91.66666667%;
}

.start,
.start-md {
  justify-content: flex-start;
  text-align: start;
}

.center,
.center-md {
  justify-content: center;
  text-align: center;
}

.end,
.end-md {
  justify-content: flex-end;
  text-align: end;
}

.top,
.top-md {
  align-items: flex-start;
}

.middle,
.middle-md {
  align-items: center;
}

.bottom,
.bottom-md {
  align-items: flex-end;
}

.around,
.around-md {
  justify-content: space-around;
}

.between,
.between-md {
  justify-content: space-between;
}

.first,
.first-md {
  order: -1;
}

.last,
.last-md {
  order: 1;
}

@media (min-width: $lg-viewport) {
  .container {
    width: $container-lg;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

.flex-fill {
  flex: 1 1 auto !important;
}

@for $i from 1 through 20 {
  .w-#{$i}pc {
    width: $i*5% !important;
  }
}

.w-500px {
  width: 500px !important;
}

.w-100 {
  width: 100%;
}
