.vote-chart {
  width: 260px;
  display: flex;
  &-title {
    font-weight: 500;
    margin: 0 0 $indent * 2;
  }
  &-item {
    height: 24px;
    line-height: 24px;
    color: $white;
    font-weight: 500;
    text-align: center;
    &.vote-status {
      &-approval {
        background-color: $status-approval-needed;
      }
      &-go {
        background-color: $status-go;
      }
      &-no {
        background-color: $status-no;
      }
      &-returned {
        background-color: $status-return-for-details;
      }
    }
  }
}
