.header-top {
  background-color: #fafafa;
  margin: 0;
  padding: $indent 0;
  position: absolute;
  top: $indent;
  left: $indent;
  border-radius: $border-radius-sm;
  box-shadow: $shadow-md;
  padding: $indent * 2;

  &-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      display: block;
      padding-right: $indent * 4;

      > a {
        font-size: $font-size-sm;
        display: block;
        color: $primary-dark;
      }
    }
  }
}
