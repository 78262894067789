body {
  font-size: $text-font-size;
  line-height: $text-line-height;
  font-weight: $text-weight;
  color: $text-color;
  background-color: $body-bg;
  font-family: $font-family-base;

  &.body-auth {
    background-color: $body-auth-bg;
  }
}

.body-not-found {
  margin-top: -40px;
  padding-top: 40px;
  background: url("../images/not-found-bg.png") 85% 0 no-repeat;
  background-size: 950px auto;
}

hr {
  border: none;
  border-bottom: 2px solid #d8dfe9;
  margin: $indent * 3 0;
}

* {
  outline: none !important;
  box-sizing: border-box;
}
