.loading {
  min-height: 230px;
  position: relative;

  &::before {
    top: 54px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: absolute;
    content: '';
    display: block;
    background: rgba(255, 255, 255, 0.95) url("../images/loader.gif") 50% 50% no-repeat;
  }

  &.small {
    min-height: 70px;
  }

  &-text {
    width: 500px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -250px;
    z-index: 2;
    text-align: center;
  }
}
