[class^="status-"] {
  display: block;
  position: relative;
  padding-left: 12px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    height: 6px;
    width: 6px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
}

.status {
  &-active {
    color: $status-active;

    &:before {
      background-color: $status-active;
    }
  }

  &-complete {
    color: $status-completed;

    &:before {
      background-color: $status-completed;
    }
  }

  &-canceled {
    color: $status-canceled;

    &:before {
      background-color: $status-canceled;
    }
  }
}