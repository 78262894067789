.form {
  &-group {
    margin-bottom: $indent * 6;
    position: relative;
  }

  &-label,
  &-count,
  &-control-static,
  &-control-static-sm {
    color: $form-label-color;
    font-size: $font-size-base;
    display: block;
    font-weight: 500;

    a {
      text-decoration: none;
    }
  }

  &-control-static {
    padding: 12px 0;

    .ico {
      transform: translateY(2px);
    }
  }

  &-control-static-sm {
    padding: 10px 0;
  }

  &-label,
  &-count {
    line-height: 20px;
    margin-bottom: $indent * 2;
  }

  &-count {
    color: $primary-light;
  }

  &-select-wrap,
  &-control {
    width: 100%;
    border: $form-control-border;
    border-radius: $border-radius-xs;
  }

  &-select-wrap {
    position: relative;
    &::before {
      display: block;
      position: absolute;
      content: '';
      top: 50%;
      right: 10px;
      margin: -6px 0 0;
      z-index: 0;
      @include ico-val($ico-angle-down);
      width: 12px;
      height: 12px;
      line-height: 12px;
      font-size: 12px;
    }
  }

  &-control,
  &-select {
    line-height: $form-control-line-height;
    padding: $form-control-padding;
    display: block;
    box-sizing: border-box;
    font-size: $font-size-base;
  }

  &-select {
    width: 100%;
    height: 44px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    background-color: $white;
    border: none;
  }

  &-control {
    resize: none;

    &:disabled {
      color: $primary-light;
      background-color: $form-control-disabled-bg;
    }

    &::-webkit-input-placeholder {
      color: $form-control-placeholder-color;
      text-transform: none;
    }

    &::-moz-placeholder {
      color: $form-control-placeholder-color;
      text-transform: none;
    }

    &:-ms-input-placeholder {
      color: $form-control-placeholder-color;
      text-transform: none;
    }

    &:-moz-placeholder {
      color: $form-control-placeholder-color;
      text-transform: none;
    }

    &:focus {
      border: $form-control-border-focus;
    }

    &-wrap {
      display: block;
      position: relative;
    }
  }

  &-loading {
    &-ico {
      width: 44px;
      height: 44px;
      line-height: 24px;
      padding: 10px;
      text-align: center;
      background-color: $white;
      border-radius: 50%;
      box-shadow: $shadow-md;
      font-size: 22px;
      display: block;
      position: absolute;
      box-sizing: border-box;
      left: 0;
      top: 50%;
      z-index: 1;
      margin: -22px 0 0;
      color: $primary;

      &,
      &::before {
        cursor: pointer;
      }

      &::before {
        content: $ico-hourglass;
        font-family: 'ico';
      }
    }
  }

  &-file {
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;

    &-wrap {
      position: relative;
      max-width: 520px;
    }

    &-label {
      position: relative;
      line-height: 24px;
      padding: 10px 110px 10px 64px;
      font-weight: 500;
      color: $primary;
      word-break: break-all;
    }

    &-ico {
      width: 44px;
      height: 44px;
      line-height: 24px;
      padding: 10px;
      text-align: center;
      background-color: $white;
      border-radius: 50%;
      box-shadow: $shadow-md;
      font-size: 22px;
      display: block;
      position: absolute;
      box-sizing: border-box;
      left: 0;
      top: 50%;
      z-index: 1;
      margin: -22px 0 0;
      color: $primary;

      &,
      &::before {
        cursor: pointer;
      }

      &::before {
        content: $ico-upload;
        font-family: 'ico';
      }
    }

    &-remove {
      position: absolute;
      top: 50%;
      right: 0;
      display: inline;
      line-height: 24px;
      color: $danger;
      font-weight: 400;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      padding: 0 0 0 15px;
      background: transparent;
      border: 0;
      outline: 0;

      &:hover {
        text-decoration: none;
        color: darken($danger, 10%);
      }

      &::before {
        width: 2px;
        height: 24px;
        left: 0;
        top: 50%;
        margin: -12px 0 0;
        background-color: #d8dfe9;
        content: '';
        display: block;
        position: absolute;

      }

      .ico {
        margin-right: 8px;
        font-size: 11px;
      }
    }
  }

  &-radio-custom {
    position: relative;
    padding: 0 8px;
    display: inline-block;
    background-color: transparent !important;

    &:nth-child(1) {
      padding-left: 0;
    }

    &:nth-last-child(1) {
      padding-right: 0;
    }

    &-wrap {
      display: flex;
      padding-top: 8px;
    }

    &-input {
      width: 1px;
      height: 1px;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }

    &-label {
      min-width: 100px;
      display: block;
      text-align: center;
      box-shadow: $shadow-sm;
      padding: 4px $indent * 3;
      background-color: $white;
      cursor: pointer;
      font-weight: 500;
      color: $primary;
      box-sizing: border-box;
      border-radius: $border-radius-xs;
    }

    &-input:checked + &-label {
      color: $white;
      background-color: $active;
    }

    &.status {
      &-approval {
        .form-radio-custom-input:checked + .form-radio-custom-label {
          background-color: $status-approval-needed;
        }
      }
      &-go {
        .form-radio-custom-input:checked + .form-radio-custom-label {
          background-color: $status-go;
        }
      }
      &-no {
        .form-radio-custom-input:checked + .form-radio-custom-label {
          background-color: $status-no;
        }
      }
      &-returned {
        .form-radio-custom-input:checked + .form-radio-custom-label {
          background-color: $status-return-for-details;
        }
      }
    }
  }

  &-error {
    /*
    display: block;
    color: $danger;
    padding: $indent 0 0;
    font-size: $font-size-sm;
    */

    width: auto;
    min-width: 160px;
    max-width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $white;
    margin: 8px 0 0;
    z-index: 1;
    display: block;
    box-shadow: $shadow-sm;
    font-size: $font-size-sm;
    color: $danger;
    padding: 8px 10px;

    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 4px 4px;
      border-color: transparent transparent $white transparent;
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: 20px;
    }

    &-wrap {
      position: relative;
      //margin-bottom: $indent * 12;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      .input-group,
      .form-control {
        border-color: $danger;
      }
    }
  }

  &-checkbox,
  &-radio {
    position: relative;
    display: inline-block;

    &-input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-label {
      position: relative;
      display: inline-block;
      padding-left: $indent * 6;
      cursor: pointer;
      &::before,
      &::after {
        top: 50%;
        left: 0;
        content: '';
        position: absolute;
        box-sizing: border-box;
        cursor: pointer;
      }

      &::before {
        width: 20px;
        height: 20px;
        border: 2px solid #D8DFE9;
        display: block;
        margin: -10px 0 0;
        background: white;
      }

      &::after {
        display: none;
      }
    }

    &-input:checked + &-label {
      font-weight: 500;
    }
  }

  &-checkbox {
    &-input {
    }
    &-label {
      &::before {
        width: 20px;
        height: 20px;
        border-radius: $border-radius-sm;
      }
      &::after {
        @include ico-val($ico-check);
        display: none;
        color: $white;
        text-align: center;
        width: 20px;
        height: 20px;
        line-height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        margin: -10px 0 0;
        font-size: 10px;
      }
    }

    &-input:checked + &-label {
      &::before {
        background-color: $primary;
        border-color: $primary;
      }
      &::after {
        display: block;
      }
    }
  }

  &-radio {
    &-input {}
    &-label {

      &::before,
      &::after {
        border-radius: 50%;
      }

      &::before {

      }

      &:after {
        width: 10px;
        height: 10px;
        margin: -5px 0 0 5px;
        background-color: $primary;
      }
    }

    &-input:checked + &-label::after {
      display: block;
    }
  }
}
