.table-draggable {
  margin-bottom: 25px;

  &-row {
    display: flex;
    align-items: center;
    padding: 22px 0;
    margin: 0 0 -1px;
    border-top: 1px solid #d8dfe9;
    border-bottom: 1px solid #d8dfe9;

    &:hover {
      background: #f0f8ff;
    }
  }

  &-control {
    position: relative;
    cursor: move;
    height: 48px;
    width: 44px;
    padding: 29px 0 0 15px;

    span {
      display: block;
      position: relative;
      height: 3px;
      margin: 0 0 6px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 3px;
        width: 3px;
        background: #015591;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }

      &:after {
        left: 8px;
      }
    }
  }
}
