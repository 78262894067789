.react-datepicker {
  box-shadow: $shadow-md;

  &-wrapper {
    display: table-cell;
    .form-control {
      border: none;
    }
  }

  .react-datepicker {
    &__header {
      background-color: #fafafa;
    }
    &__navigation {
      width: 26px;
      height: 26px;
      background: transparent;
      color: $primary;
      border: none;
      top: 7px;
      &::after {
        width: 26px;
        height: 26px;
        top: 50%;
        left: 50%;
        text-indent: 0;
        margin: -13px 0 0 -13px;
        position: absolute;
      }
      &--previous {
        &::after {
          @include ico-val($ico-arrow-left);
        }
      }
      &--next {
        &::after {
          @include ico-val($ico-arrow-right);
        }
      }
    }

    &__current-month,
    &-time__header,
    &-year-header {
      font-weight: normal;
      color: $primary-dark;
    }
  }
  &-popper[data-placement^="top"] .react-datepicker__triangle {
    margin-bottom: -7px;
  }
  &-popper[data-placement^="bottom"] .react-datepicker__triangle {
    margin-top: -7px;
  }
}
