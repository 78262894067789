.list {
  &-unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-indent {
    > li {
      margin: 10px 0;
    }
  }
}
