.activity {
  &-item {
    &-content {
      p {
        margin: 0 0 $indent * 2;
      }

      *:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  &-status {
    &-prev,
    &-new {
      min-width: 100px;
      color: $white;
      box-shadow: $shadow-md;
      line-height: 32px;
      padding: 4px 20px;
      border-radius: $border-radius-xs;
      display: inline-block;
      position: relative;
      font-weight: 500;
      margin-right: 40px;
      text-align: center;
      box-sizing: border-box;
    }

    &-prev {
      &::after {
        display: block;
        position: absolute;
        @include ico-val($ico-arrow-right);
        font-size: 11px;
        color: $primary-darker;
        height: 20px;
        line-height: 20px;
        top: 50%;
        left: 100%;
        margin: -10px 0 0 13px;
      }
    }
  }
}
