.tag {
  min-width: 92px;
  display: inline-block;
  background-color: $tag-bg;
  color: $white;
  box-sizing: border-box;
  line-height: 20px;
  padding: 2px 10px;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
  font-size: $font-size-sm;
  border-radius: 12px;

  &-refine {
    background-color: $refine;
  }
  &-concept {
    background-color: $concept;
  }
  &-estimate {
    background-color: $estimate;
  }
  &-action {
    background-color: $action;
  }
  &-test {
    background-color: $test;
  }
  &-execute {
    background-color: $execute;
  }

  &-filter {
    &-row {
      display: inline-block;
      margin: 8px 25px 8px 0;
    }

    &-label {
      color: $primary-light;
      font-weight: 500;
      margin-right: 10px;
    }

    &-value {
      font-weight: 500;
    }

    &-tag {
      position: relative;
      display: inline-block;
      line-height: 32px;
      font-weight: 500;
      margin-right: 15px;
      padding: 0px 40px 0px 12px;
      background: #e7edfa;
      -webkit-border-radius: 32px;
      -moz-border-radius: 32px;
      border-radius: 32px;

      &-control {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        text-align: center;
        font-size: 21px;
        color: $danger;
        width: 32px;
        background: transparent;
        border: 0;
        border-left: 1px solid white;
        outline: 0;
      }
    }
  }
}
