@mixin ico($var) {
  &::before {
    content: $var;
  }
}

@mixin ico-include($var) {
  display: inline-block;
  font-family: 'ico', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: $var;
  }
}

@mixin text-ico($var) {
  position: relative;
  padding-left: 30px;

  &::before {
    content: $var;
    display: block;
    width: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'ico', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 16px;
  }
}

@mixin ico-val($var) {
  display: inline-block;
  font-family: 'ico', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: $var;
}

.ico {
  display: inline-block;
  font-family: 'ico', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-xs {
  font-size: 12px;
}

.ico {
  &.ico-doc {
    font-size: 20px !important;
  }

  &.ico-calendar {
    font-size: 24px !important;
  }

  &.ico-activity-log {
    font-size: 27px !important;
  }

  &.ico-delete {
    font-size: 26px !important;
  }

  &.ico-edit {
    font-size: 26px !important;
  }

  &.ico-visible {
    font-size: 16px !important;
    transform: translateY(-2px);
  }

  &.ico-hidden {
    font-size: 21px !important;
  }

  &.ico-admin {
    font-size: 28px;
  }

  &.ico-dependency {
    font-size: 28px;
  }

  &.ico-comment {
    font-size: 19px;
  }

  &.ico-plus {
    font-size: 14px;
  }

  &.ico-logout {
    font-size: 20px;
    margin-right: 10px;
  }

  &.ico-settings {
    font-size: 14px;
  }
}
