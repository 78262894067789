.notification {
  &-list {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: block;
    background-color: $white;
    box-shadow: $shadow-md;
    margin: 0;
    padding: 28px 0;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 28px 0 0 0;
    margin: 28px 0 0 0;
    border-top: 1px solid #d8dfe9;

    &:first-child {
      border-top: 0;
      padding: 0;
      margin: 0;
    }

    &-icon {
      flex: 0 0 43px;

      .success,
      .error {
        color: white;
        text-align: center;
        line-height: 31px;
        font-size: 14px;
        height: 28px;
        width: 28px;
        border-radius: 28px;
      }

      .success {
        background-color: $success;
      }

      .error {
        background-color: $danger;
      }
    }

    &-text {
      flex: 1;
    }

    &-close {
      flex: 0 0 40px;
      color: $primary-light;
      padding: 0 7px;
      font-size: 18px;

      button {
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
      }

      .ico {
        width: 26px;
        height: 26px;
        line-height: 26px;
      }

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
}
