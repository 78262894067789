.main {
  &-wrap {
    margin: 0 0 $indent * 6;
  }

  &-header {
    padding: $main-header-padding;
    margin: 0 0 $indent * 8;
    box-shadow: $shadow-md;
    background-color: $white;

    &-logo {
      display: block;
      margin: -12px 0;

      img {
        width: auto;
        height: 80px;
        display: block;
      }
    }
  }
}
