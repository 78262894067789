.media {
  width: 100%;
  display: table;
  &-aside,
  &-body {
    display: table-cell;
    vertical-align: top;
  }
  &-aside {
    width: 1px;
  }
}
