.milestone-list {
  list-style: none;
  padding: 0 0 0 30px;
  margin: 0;
  
  li {
    position: relative;
    padding: 15px 0;
    border-bottom: 1px dashed #d8dfe9;

    &:before {
      content: '';
      position: absolute;
      left: -24px;
      top: 30px;
      width: 0;
      height: 100%;
      border-left: $form-control-border
    }

    &:after {
      content: '';
      position: absolute;
      left: -29px;
      top: 22px;
      width: 8px;
      height: 8px;
      background: white;
      border: $form-control-border;
      border-radius: 50%;
    }

    &:first-child,
    &:last-child {
      &:after {
        border-color: #768aa7;
      }
    }

    &:last-child {
      border-bottom: 0;

      &:before {
        display: none;
      }
    }
  }
}