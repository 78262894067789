.comment {
  &-header {
    margin: 0 0 $indent * 2;
  }
  &-list {
    border-top: 1px solid #d8dfe9;
  }
  &-item {
    min-height: 84px;
    border-bottom: 1px solid #d8dfe9;
    padding: $indent * 3 0;
    box-sizing: border-box;

    &-avatar {
      width: 58px;
    }

    &-content {
      p:nth-last-child(1) {
        margin: 0;
      }
    }

    &-action {
      padding: $indent * 4 0 0 $indent * 4;
    }

    &-empty {
      color: #94a1b3;
      font-weight: 500;
      text-align: center;
      padding: 17px 0;
      background: #f5f6f8;

      .ico {
        margin-right: 10px;
        transform: translateY(4px);
      }
    }
  }
}
