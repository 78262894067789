@font-face {
  font-family: 'ico';
  src: url('../fonts/ico.eot?yunmrw');
  src:
          url('../fonts/ico.eot?yunmrw#iefix') format('embedded-opentype'),
          url('../fonts/ico.woff?yunmrw') format('woff'),
          url('../fonts/ico.ttf?yunmrw') format('truetype'),
          url('../fonts/ico.svg?yunmrw#ico') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

$ico-activity-log: '\E001';
$ico-admin: '\E002';
$ico-angle-down: '\E003';
$ico-arrow-double-left: '\E004';
$ico-arrow-double-right: '\E005';
$ico-arrow-left: '\E006';
$ico-arrow-right: '\E007';
$ico-calendar: '\E008';
$ico-chat: '\E009';
$ico-check: '\E00A';
$ico-close-thin: '\E00B';
$ico-close: '\E00C';
$ico-comment: '\E00D';
$ico-delete: '\E00E';
$ico-dependency: '\E00F';
$ico-doc: '\E010';
$ico-edit: '\E011';
$ico-hidden: '\E012';
$ico-hourglass: '\E013';
$ico-logout: '\E014';
$ico-plus: '\E015';
$ico-search: '\E016';
$ico-settings: '\E017';
$ico-sort-asc: '\E018';
$ico-sort-desc: '\E019';
$ico-table: '\E01A';
$ico-upload: '\E01B';
$ico-visible: '\E01C';


.ico-activity-log {
  @include ico($ico-activity-log);
}

.ico-admin {
  @include ico($ico-admin);
}

.ico-angle-down {
  @include ico($ico-angle-down);
}

.ico-arrow-double-left {
  @include ico($ico-arrow-double-left);
}

.ico-arrow-double-right {
  @include ico($ico-arrow-double-right);
}

.ico-arrow-left {
  @include ico($ico-arrow-left);
}

.ico-arrow-right {
  @include ico($ico-arrow-right);
}

.ico-calendar {
  @include ico($ico-calendar);
}

.ico-chat {
  @include ico($ico-chat);
}

.ico-check {
  @include ico($ico-check);
}

.ico-close-thin {
  @include ico($ico-close-thin);
}

.ico-close {
  @include ico($ico-close);
}

.ico-comment {
  @include ico($ico-comment);
}

.ico-delete {
  @include ico($ico-delete);
}

.ico-dependency {
  @include ico($ico-dependency);
}

.ico-doc {
  @include ico($ico-doc);
}

.ico-edit {
  @include ico($ico-edit);
}

.ico-hidden {
  @include ico($ico-hidden);
}

.ico-hourglass {
  @include ico($ico-hourglass);
}

.ico-logout {
  @include ico($ico-logout);
}

.ico-plus {
  @include ico($ico-plus);
}

.ico-search {
  @include ico($ico-search);
}

.ico-settings {
  @include ico($ico-settings);
}

.ico-sort-asc {
  @include ico($ico-sort-asc);
}

.ico-sort-desc {
  @include ico($ico-sort-desc);
}

.ico-table {
  @include ico($ico-table);
}

.ico-upload {
  @include ico($ico-upload);
}

.ico-visible {
  @include ico($ico-visible);
}

