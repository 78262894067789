.pagination {
  list-style: none;
  margin: 0 0 $indent * 3;
  padding: 0;
  @extend .clearfix;

  > li {
    min-width: 44px;
    height: 44px;
    text-align: center;
    display: block;
    margin: 0 15px 0 0;
    @extend .pull-left;

    > a,
    > button,
    > span {
      height: 30px;
      line-height: 20px;
      padding: 5px;
      min-width: 30px;
      margin: 0 auto;
      display: inline-block;
      box-sizing: border-box;
      color: $primary;
      border-radius: 15px;
      font-weight: 500;
      border: none;
      transform: translateY(7px);

      &:hover {
        text-decoration: none;
        color: $primary-dark;
      }

      &.pagination-btn {
        width: 44px;
        height: 44px;
        min-width: 44px;
        line-height: 34px;
        overflow: hidden;
        background-color: $white;
        box-shadow: $shadow-sm;
        border-radius: $border-radius-sm;
        font-size: $font-size-sm;
        transform: translateY(0);

        &:not(:disabled) {
          &:hover {
            color: $white;
            background-color: $active;
          }
        }
      }

      &.disabled,
      &:disabled {
        box-shadow: none;
        color: #d9e0e6;
        cursor: default;
      }
    }

    &.active {
      > a,
      > span,
      > button {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .ico {
    &.ico-arrow-double-left,
    &.ico-arrow-left,
    &.ico-arrow-right,
    &.ico-arrow-double-right {
      transform: translateY(2px);
    }
  }
}
