@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $indents {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }

    .#{$abbrev}t-#{$size} {
      #{$prop}-top: $length !important;
    }

    .#{$abbrev}r-#{$size} {
      #{$prop}-right: $length !important;
    }

    .#{$abbrev}b-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    .#{$abbrev}l-#{$size} {
      #{$prop}-left: $length !important;
    }

    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
      #{$prop}-right: $length !important;
    }

    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
      #{$prop}-bottom: $length !important;
    }
  }
}
