.min {
  &w {
    &-15 {
      min-width: 150px;
    }
    &-17 {
      min-width: 170px;
    }
    &-22 {
      min-width: 220px;
    }
  }
  &h {}
}

.max {
  &w {
    &-14 {
      max-width: 140px;
    }
  }
}

.lh-base {
  line-height: 24px !important;
}

.text-iconed {
  height: 24px !important;
  line-height: 14px !important;

  .ico {
    &.ico-doc {
      transform: translateY(5px);
    }

    &.ico-calendar {
      transform: translateY(6px);
    }
  }
}

.hr-dashed {
  border: none;
  border-bottom: 1px dashed #d8dfe9;
  margin: 0 0 $indent * 3;
}

.vertical {
  &-top {
    vertical-align: top !important;
  }

  &-middle {
    vertical-align: middle !important;
  }
}

.d {
  &-none {
    display: none !important;
  }

  &-block {
    display: block !important;
  }
}
