.header-line {
  margin: $indent * 2 0 $indent * 5;
  @extend .clearfix;

  h1,
  h2,
  h3,
  p {
    margin: 0;
    line-height: 30px;

  }
}
