.tooltip {
  &-comment {
    display: none;
    position: absolute;
    right: 0;
    top: 47px;
    width: 425px;
    padding: 4px 0 0;
    z-index: 100;
  }

  &-arrow {
    position: absolute;
    right: 16px;
    top: 0;
    border-bottom: 4px solid white;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    z-index: 101;
  }

  &-body {
    padding: 10px;
    background: white;
    box-shadow: $shadow-md;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &-date {
    float: right;
    color: #768AA7;
  }

  &-title {
    color: #434C59;
    font-weight: 500;
    margin: 0 0 10px;
  }

  &-common {
    display: inline-block;
    position: relative;
  }

  &-target {
    text-align: center;
    color: white;
    line-height: 16px;
    cursor: pointer;
    font-size: 13px;
    height: 16px;
    width: 16px;
    background: #acb3bd;
    border-radius: 16px;

    &:hover {
      & ~ .tooltip-popup {
        display: block;
      }
    }
  }

  &-popup {
    display: none;
    position: absolute;
    top: -8px;
    left: 25px;
    text-align: center;
    color: white;
    white-space: nowrap;
    font-size: 14px;
    padding: 3px 8px;
    background: #323438;
    border-radius: 5px;
    z-index: 100;

    &:after {
      content: '';
      position: absolute;
      left: -5px;
      top: 9px;
      border-right: 5px solid #323438;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }
  }
}