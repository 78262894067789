.block {
  max-width: $block-width;
  margin: 0 auto;
  background-color: $white;
  box-shadow: $shadow-lg;
  padding: $block-padding;
  box-sizing: border-box;
  border-bottom-left-radius: $border-radius-md;
  border-bottom-right-radius: $border-radius-md;

  &-header {
    margin: 0 0 $indent * 4;
    @extend .clearfix;
  }
  &-title {
    position: relative;
    padding: $indent * 4 0 $indent * 2;

    &:after {
      height: 3px;
      position: absolute;
      background-image: linear-gradient(to left, #ffffff, #f0f3f7 73%);
      content: '';
      display: block;
      top: 100%;
      left: -($indent * 5);
      right: -($indent * 5);
    }
  }
  &-logo {
    img {
      width: auto;
      height: 80px;
      display: block;
    }
  }
  &-body {
  }
}
