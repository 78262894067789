.timeline {
  &-list {
    position: relative;
    display: flex;
    padding-top: 60px;
    margin: 0 0 $indent * 8;
  }

  &-item {
    width: calc(100% / 5);
    font-size: $font-size-sm;
    border-top: 2px solid #d8dfe9;
    position: relative;
    padding: $indent * 3 $indent * 3 0;

    &::before {
      display: none;
      position: absolute;
      top: -42px;
      left: -1px;
      bottom: 0;
      content: '';
      border-left: 1px solid #d8dfe9;
    }

    &-letter {
      width: 32px;
      height: 32px;
      line-height: 32px;
      top: -42px;
      left: 15px;
      position: absolute;
      font-size: $font-size-lg;
      box-shadow: $shadow-md;
      border-radius: 50%;
      text-align: center;
      font-weight: 500;
      cursor: pointer;

      &::before {
        width: 13px;
        height: 13px;
        background-color: $white;
        display: block;
        position: absolute;
        box-sizing: border-box;
        border: 2px solid #a1b3cc;
        content: '';
        border-radius: 50%;
        left: -22px;
        bottom: -15px;
      }
    }

    &-title {
      font-weight: 500;
    }

    &-info {
      display: flex;
      margin: 0 0 $indent;

      dt,
      dd {
        line-height: $line-height-base;
        margin: 0;
      }

      dt {
        width: 50px;
        color: #768aa7;
      }

      dd {
        color: $primary-darker;
      }
    }

    &-inner {
      display: none;
    }

    &.filled {
      border-top-color: $success;

      .timeline-item-letter::before {
        border-color: $success;
      }
    }

    &.failed {
      border-top-color: $danger;

      .timeline-item-letter::before {
        border-color: $danger;
      }
    }

    &:nth-child(1).active .timeline-item-letter{
      background-color: $concept;
    }

    &:nth-child(2).active .timeline-item-letter{
      background-color: $refine;
    }

    &:nth-child(3).active .timeline-item-letter{
      background-color: $estimate;
    }

    &:nth-child(4).active .timeline-item-letter{
      background-color: $action;
    }

    &:nth-child(5).active .timeline-item-letter{
      background-color: $test;
    }

    &:nth-child(6).active .timeline-item-letter{
      background-color: $execute;
    }

    &.active {
      &::before {
        display: block;
      }

      .timeline-item {
        &-letter {
          color: $white;
        }

        &-inner {
          display: block;
        }
      }
    }

    &.last {
      position: absolute;
      right: 0;
      top: -20px;
      width: 130px;
      border-top: 0;

      &:before {
        width: 13px;
        height: 13px;
        background-color: $white;
        display: block;
        position: absolute;
        top: 74px;
        right: 0;
        left: auto;
        box-sizing: border-box;
        border: 2px solid #004b86;
        content: '';
        z-index: 2;
        border-radius: 50%;
      }
      
      .timeline-item-inner {
        text-align: right;
      }

      .timeline-item-info {
        justify-content: flex-end;
      }
    }
  }
}
