// Indent
$indent: 5px;

$indents: ();
$indents: map-merge(
  (
    0: 0,
    1: $indent,
    2: $indent * 2,
    3: $indent * 3,
    4: $indent * 4,
    5: $indent * 5,
    6: $indent * 6,
    7: $indent * 7,
    8: $indent * 8,
    9: $indent * 9,
    10: $indent * 10,
    16: $indent * 16,
    'auto': auto,
    'label': $indent * 6
  ),
  $indents
);

// Grid
$gutter-width: 15px;
$outer-margin: $gutter-width * 2;
$gutter-compensation: -($gutter-width / 2);
$half-gutter-width: $gutter-width / 2;
$md-min: 1024px;
$lg-min: 1124px;
$screen-md-min: $md-min;
$screen-lg-min: $lg-min;
$container-md: $md-min + $gutter-width * 2;
$container-lg: $lg-min + $gutter-width * 2;

$md-viewport: 1024px;
$lg-viewport: 1200px;

// Font family
$font-family-base: 'Red Hat Text';

// Font size
$font-size-sm: 14px;
$font-size-base: 16px;
$font-size-lg: 18px;
$line-height-base: 24px;

$font-size-h1: 24px;
$font-size-h2: 22px;
$font-size-h3: 20px;

// Colors
$white: #fff;
$body-bg: $white;
$body-auth-bg: #eff1f5;

$primary-lighter: #e7edfa;
$primary-light: #7b889a;
$primary: #004b86;
$primary-dark: #063051;
$primary-darker: #032139;

$danger: #db342a;
$warning: #f6a217;
$success: #76ce3b;

$active: #55bcff;

$tag-bg: #0088f2;

$status-active: #55BCFF;
$status-completed: #8CC63E;
$status-canceled: #DB342A;

$text-completed: #8C07D6;

// Text
$text-font-size: $font-size-base;
$text-line-height: $line-height-base;
$text-color: $primary-dark;
$text-weight: 400;

// Shadow
$shadow-sm: 0 0 8px 0 rgba(71, 100, 137, 0.3);
$shadow-md: 0 0 12px 0 rgba(58, 125, 211, 0.3);
$shadow-lg: 0 0 14px 0 rgba(58, 125, 211, 0.3);

// Border radius
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 6px;

// Status
$status-approval-needed: #55bcff;
$status-go: #76ce3b;
$status-no: #f54c41;
$status-return-for-details: #ffb230;

// Tag colors
$refine: #55bcff;
$concept: #004b86;
$estimate: #8cc63e;
$action: #f6a217;
$test: #f26820;
$execute: #d6077d;

// Form
$form-label-color: #434c59;
$form-control-height: 48px;
$form-control-line-height: 20px;
$form-control-padding: 12px 10px;
$form-control-color: #063051;
$form-control-radius: $border-radius-xs;
$form-control-border: 2px solid #d8dfe9;
$form-control-border-focus: 2px solid #c8dff1;
$form-control-placeholder-color: $primary-light;
$form-control-count-color: $primary-light;
$form-control-disabled-bg: #e1e6ec;

// Buttons
$btn-sm-size: 32px;
$btn-md-size: 44px;
$btn-lg-size: 52px;

$btn-md-padding: 10px 20px;
$btn-md-font-weight: 500;
$btn-md-line-height: 24px;
$btn-md-border: none;
$btn-md-border-radius: $border-radius-sm;

$btn-disabled-color: #7b889a;
$btn-disabled-bg: #e1e6ec;

// Block
$block-width: 570px;
$block-padding: 14px 25px 25px;

// Main header
$main-header-padding: 12px 0;

// Nav Main
$nav-main-padding: 17px 0;
$nav-main-item-padding: 0 10px;
$nav-main-link-color: $primary-darker;
$nav-main-link-active-color: $primary;
$nav-main-link-active-border: 2px solid $danger;
$nav-main-link-padding: 10px;

// React datepicker
$datepicker__background-color: $white;
$datepicker__border-color: none;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: $primary-light;
$datepicker__selected-color: $primary-dark;
$datepicker__text-color: $primary-darker;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: $border-radius-md;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: inherit;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;
