.nav {
  &-main {
    list-style: none;
    margin: 0;
    padding: 6px 0;
    text-align: center;

    > li {
      display: inline-block;
      padding: $nav-main-item-padding;
      > a {
        display: block;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        color: $nav-main-link-color;
        padding: $nav-main-link-padding;

        &:hover {
          color: $nav-main-link-active-color;
          text-decoration: none;
        }
      }

      &.active {
        > a {
          color: $nav-main-link-active-color;

          &::after {
            left: 0;
            right: 0;
            bottom: 2px;
            border-bottom: $nav-main-link-active-border;
            content: '';
            display: block;
            position: absolute;
          }
        }
      }

      &.nav-main-li-dot {
        > a {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            overflow: hidden;
            display: block;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: $danger;
          }
        }
      }
    }
  }

  &-stage {
    list-style: none;
    margin: 0 0 $indent * 6;
    padding: 0;
    display: flex;
    box-shadow: $shadow-lg;
    border-radius: $border-radius-md;

    > li {
      width: 16.666%;
      position: relative;

      &::after {
        width: 0;
        top: 8px;
        bottom: 8px;
        right: -1px;
        border-right: 1px solid #d8dfe9;
        content: '';
        display: block;
        position: absolute;
      }

      &:nth-last-child(1) {
        &::after {
          display: none;
        }
      }

      > a {
        height: 44px;
        box-sizing: border-box;
        font-size: $font-size-base;
        line-height: $line-height-base;
        text-align: center;
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        padding: 10px 15px;

        &:hover {
          text-decoration: none;
        }
      }

      &:nth-child(1) > a {
        border-top-left-radius: $border-radius-md;
        border-bottom-left-radius: $border-radius-md;
      }

      &:nth-last-child(1) > a {
        border-top-right-radius: $border-radius-md;
        border-bottom-right-radius: $border-radius-md;
      }

      &.active {
        &::after {
          display: none;
        }

        > a {
          color: $white;
          background-color: $active;
        }
      }

      &.active.nav-stage {
        &-concept > a {
          background-color: $concept;
        }
        &-refine > a {
          background-color: $refine;
        }
        &-estimate > a {
          background-color: $estimate;
        }
        &-action > a {
          background-color: $action;
        }
        &-test > a {
          background-color: $test;
        }
        &-execute > a {
          background-color: $execute;
        }
      }
    }
  }

  &-shade {
    list-style: none;
    background-color: $white;
    letter-spacing: 0;
    padding: 0;
    margin: 0;
    box-shadow: $shadow-sm;
    border-radius: $border-radius-sm;

    > li {
      display: inline-block;

      > a {
        min-width: 60px;
        height: 44px;
        line-height: 30px;
        color: $primary;
        display: block;
        font-size: 24px;
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
      }
      &.active {
        > a {
          color: $white;
          background-color: $active;
        }
      }
      &:nth-child(1) > a {
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
      }
      &:nth-last-child(1) > a {
        border-top-right-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
      }
    }
  }

  &-status {
    list-style: none;
    margin: 0 0 $indent * 6;
    padding: 0;
    display: flex;
    box-shadow: $shadow-lg;
    border-radius: $border-radius-md;

    > li {
      position: relative;
      padding: 10px;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: 500;
      letter-spacing: 0.18px;
      width: 100%;
      text-align: center;

      &::after {
        width: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        right: -1px;
        border-right: 1px solid #d8dfe9;
        content: '';
        display: block;
        position: absolute;
      }

      &:nth-child(1) {
        border-top-left-radius: $border-radius-md;
        border-bottom-left-radius: $border-radius-md;
      }

      &:nth-last-child(1) {
        border-top-right-radius: $border-radius-md;
        border-bottom-right-radius: $border-radius-md;

        &::after {
          display: none;
        }
      }

      &.active {
        color: $white;
        background-color: $active;
      }
    }
  }
}
