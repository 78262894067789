// Default
.btn {
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  height: $btn-md-size;
  padding: $btn-md-padding;
  font-weight: $btn-md-font-weight;
  line-height: $btn-md-line-height;
  border: $btn-md-border;
  border-radius: $btn-md-border-radius;

  &.disabled,
  &:disabled {
    cursor: default !important;

    &:not(.active) {
      background-color: $btn-disabled-bg !important;
      color: $btn-disabled-color !important;

      &:hover {
        background-color: $btn-disabled-bg !important;
        color: $btn-disabled-color !important;
      }
    }

    &.active {
      opacity: 0.7;
    }
  }

  &-default {
    background: none;

    &:hover {
      background-color: $active;
      color: $white;
    }

    .ico-comment {
      transform: translate(0, 3px);
    }
  }

  &:hover {
    text-decoration: none;
  }

  &-primary {
    color: $white;
    background-color: $primary;
    border: none;

    &:hover,
    &:focus {
      background-color: darken($primary, 10%);
    }

    &:disabled {
      background-color: #e1e6ec;
      color: $primary-light;
    }
  }

  &-block {
    width: 100%;
    display: block;
  }

  &-link {
    background-color: $white;
    color: $primary;

    &:hover {
      color: darken($primary, 10%);
    }

    .ico {
      transform: translateY(4px);
    }

    &-danger {
      border: none;
      background-color: transparent;
      color: $danger;
    }
  }

  &-shade {
    box-shadow: $shadow-md;

    &:hover,
    &:focus {
      background-color: $active;
      color: $white;
    }
  }

  &-ghost,
  &-ghost-danger {
    box-shadow: $shadow-md;
    font-weight: 500;
    padding: 10px 20px;
    background-color: $white;
    border-radius: $border-radius-sm;
    border: none;

    &:disabled {
      background-color: #eff1f4 !important;
    }
  }

  &-ghost {
    color: $primary;

    &:hover {
      color: $white;
      background-color: $active;
    }

    &:disabled {
      color: $primary-light;
    }
  }

  &-ghost-danger {
    color: $danger;

    &:hover {
      color: $white;
      background-color: $danger;
    }

    &:disabled {
      color: lighten($danger, 30%);
    }
  }

  &-danger {
    color: $white;
    background-color: $danger;

    &:hover,
    &:focus {
      background-color: darken($danger, 10%);
    }

    &:disabled {
      background-color: lighten($danger, 30%);
    }
  }


  &.btn-sq {
    min-width: 44px;
    padding: $indent * 2;
  }

  &.btn-radio {
    position: relative;
    height: auto;
    min-width: 100px;
    display: inline-block;
    text-align: center;
    box-shadow: $shadow-sm;
    margin: 0 8px;
    padding: 4px $indent * 3;
    background-color: $white;
    cursor: pointer;
    font-weight: 500;
    color: $primary;
    box-sizing: border-box;
    border-radius: $border-radius-xs;

    &.active {
      background-color: $primary;
      color: $white;
    }

    &.active.btn {
      &-approval{
        background-color: $status-approval-needed;
      }
      &-go {
        background-color: $status-go;
      }
      &-no {
        background-color: $status-no;
      }
      &-returned {
        background-color: $status-return-for-details;
      }
    }
  }
}

// Sizes
.btn {
  &-sm {
    height: $btn-sm-size;
  }
  &-md {
    height: $btn-md-size;
  }
  &-lg {
    height: $btn-lg-size;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.3px;
  }
}

// Colors
.btn {
  &.btn-ghost {
    min-width: 44px;
    padding: 10px;
    box-shadow: $shadow-md;
    letter-spacing: 0.2px;
    &:hover {
      color: $white;
      background-color: $active;
    }
  }
}

// Width
.btn {
  &.btn-width {
    min-width: 190px;
  }
}

// Custom
.btn {
  .ico {
    display: inline-flex;
  }

  .ico-plus {
    transform: translateY(2px);
  }
  .ico-activity-log {
    transform: translate3d(3px, -1px, 0px);
  }

  &-ico {
    padding: 10px;
  }
}
