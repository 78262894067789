.table {
  &-default {
    width: 100%;
    margin: 0 0 $indent * 3;

    th,
    td {
      text-align: left;
      padding: 15px;
    }

    th {
      white-space: nowrap;
      font-weight: 500;
      color: #94a1b3;
      background-color: #f5f6f8;
    }

    td {
      border-bottom: 1px solid #d8dfe9;
      vertical-align: top;
    }
  }

  &-grade {
    width: 100%;
    margin: 0 0 8 * $indent;

    td {
      padding: 15px 10px;
      border: 1px solid #d8dfe9;
    }

    tr:nth-child(1) td {
      border-top: none;
    }

    tr:nth-last-child(1) td {
      border-bottom: none;
    }

    tr td:nth-child(1) {
      border-left: none;
    }

    tr td:nth-child(2) {
      white-space: nowrap;
    }

    tr td:nth-last-child(1) {
      border-right: none;
      width: 170px;
    }

    .btn {
      padding-left: 10px;
      padding-right: 10px;
    }

    .form-radio-custom-wrap {
      padding-top: 0;
    }
  }

  &-action {
    tbody tr:hover td {
      cursor: pointer;
      background-color: #f0f8ff;
    }

    &-toggle {
      cursor: pointer;

      &-inner {
        padding-right: 20px;
        display: inline-block;
        position: relative;

        .ico {
          width: 18px;
          height: 18px;
          line-height: 18px;
          font-size: 13px;
          position: absolute;
          top: 50%;
          right: 0;
          text-align: right;
          margin: -8px 0 0;
        }
      }
    }
  }

  &.table-vertical-middle {
    tbody {
      td {
        vertical-align: middle;
      }
    }
  }
}
