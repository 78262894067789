.input-group {
  display: table;
  width: 100%;
  border: $form-control-border;
  box-sizing: border-box;
  border-radius: $border-radius-sm;

  &.input-group-ghost {
    border: none;
    box-shadow: $shadow-md;

    > .form-control,
    > .input-group-prepend,
    > .input-group-append {
      &,
      .btn {
        height: 44px;
      }
    }
  }

  > .form-control {
    border: none;
    display: table-cell;
  }

  > .input-group-prepend,
  > .input-group-append {
    width: 32px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background-color: $white;
    font-size: 24px;

    .ico {
      color: $primary;
    }

    &.input-group-val {
      width: 48px;
      background-color: #dde3ec;
      color: $primary-light;
      font-size: $font-size-base !important;
    }
  }

  .btn {
    height: 44px;
    min-width: 44px;
    display: block;
    border: none;
    line-height: 28px;
    background-color: $white;
    padding-left: 12px;
    padding-right: 12px;
    color: #94a1b3;
    position: relative;
    &::before {
      height: 30px;
      top: 50%;
      left: -2px;
      margin: -15px 0 0;
      border-left: 2px solid #d8dfe9;
      content: '';
      display: block;
      position: absolute;
    }

    .ico {
      font-size: 24px;
    }
  }
}
