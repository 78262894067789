.details {
  &-header {
    border-bottom: 1px solid #d8dfe9;
    padding: $indent * 4 0 0;
    margin: $indent * 2 0 $indent * 4;

    &-line {
      border-bottom: 1px solid #d8dfe9;
      padding-bottom: $indent * 2;
      @extend .clearfix;
    }
  }
}
