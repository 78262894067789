.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.clearfix {
  &:after {
    display: block;
    clear: both;
    content: '';
  }
}

.d {
  &-block {
    display: block;
  }

  &-flex {
    display: flex;
  }

  &-inline-block {
    display: inline-block;
  }
}

.sr-only {
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;
  display: none !important;
  margin: 0 !important;
}

.relative {
  position: relative;
}
