h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 $indent * 3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: $font-size-h1;

  .tag {
    transform: translateY(-3px);
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
}

h3,
.h3 {
  font-size: $font-size-h3;
}

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.text {
  // Text color
  &-primary {
    &-lighter {
      color: $primary-lighter !important;
    }
    &-light {
      color: $primary-light !important;
    }
    & {
      color: $primary !important;
    }
    &-dark {
      color: $primary-dark !important;
    }
    &-darker {
      color: $primary-darker !important;
    }
  }

  // Status colors
  &-success {
    color: $success;
  }
  &-danger {
    color: $danger;
  }
  &-warning {
    color: $warning;
  }

  // Stage status
  &-approval {
    color: $status-approval-needed;
  }
  &-go {
    color: $status-go;
  }
  &-no {
    color: $status-no;
  }
  &-returned {
    color: $status-return-for-details;
  }
  &-completed {
    color: $text-completed;
  }

  // Font weight
  &-regular {
    font-weight: 400 !important;
  }

  &-medium {
    font-weight: 500 !important;
  }

  &-bold {
    font-weight: 700 !important;
  }

  // Text transform
  &-uppercase {
    text-transform: uppercase;
  }

  // Text align
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }

  &-nowrap {
    white-space: nowrap !important;
  }

  // Text custom
  &-cutted {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }

  // Text ellipsis
  &-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}

// Statuses bg
.status {
  &-approval {
    background-color: $status-approval-needed;
  }
  &-go {
    background-color: $status-go;
  }
  &-no {
    background-color: $status-no;
  }
  &-returned {
    background-color: $status-return-for-details;
  }
}

.text-empty-col {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: $primary-light !important;
}
