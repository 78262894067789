.ReactModal__Overlay {
  overflow-y: auto;
  z-index: 5;
}

.modal {
  background-color: $white;
  border-radius: $border-radius-md;
  box-shadow: $shadow-lg;
  box-sizing: border-box;

  &.modal-sm {
    width: 580px;
    margin: 160px auto 50px;
  }

  &.modal-xs {
    width: 470px;
    margin: 160px auto 50px;
  }

  &-header {
    padding: 25px 25px 10px;
    position: relative;
    &::before {
      height: 3px;
      position: absolute;
      background-image: linear-gradient(to left, #ffffff, #f0f3f7 73%);
      content: '';
      display: block;
      top: 100%;
      left: 0;
      right: 0;
    }
  }

  &-title {
    margin: 0;
    line-height: 32px;
  }

  &-close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 25px;
    margin: -5px 0 0;
    background-color: $white;
    color: $primary-light;
    border: none;
    padding: 2px;
    &:hover {
      color: $primary;
    }
  }
  &-body {
    padding: 20px 25px 0;
  }
  &-footer {
    padding: 5px 25px 25px;
  }
}
